import React from 'react';
import './App.css';
import {MapComponent} from "./components/Map";
import {queryClient} from "./queries/client";
import {
  QueryClientProvider,
} from 'react-query'

function App() {
  return (
      <QueryClientProvider client={queryClient}>
        <MapComponent />
      </QueryClientProvider>
  );
}

export default App;
