import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, Polygon, Polyline, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {divIcon, LatLngBounds, LatLngBoundsExpression, LatLngTuple, LeafletMouseEvent} from 'leaflet';
import {difference, intersect, polygon, Position, union} from '@turf/turf';
import {MapEventHandler} from "./MapEventHandler";
import {israelBorders, MaskLayer} from "./MaskLayer";
import {useCoordinates} from "./queryHooks";

const DrawerIcon = divIcon({className: 'my-div-icon'});
const OutCutIcon = divIcon({className: 'out-cut-icon'});

const coordinateReverse = (coord: [number, number]): [number, number] => {
    const [lat = 0, lng = 0] = coord;
    return [lng, lat];
}

enum Tool {
    drawer,
    cleaner,
    scissors,
}

const northLat = 34.0;
const westLng = 33.5;
const southLat = 26.5;
const eastLng = 36.5;
const maxBounds = new LatLngBounds([northLat, westLng], [southLat, eastLng]);

export const MapComponent: React.FC = () => {
    const {data, mutate} = useCoordinates()
    const [coordinates, setCoordinates] = useState<LatLngTuple[]>([]);
    const [polygons, setPolygons] = useState<LatLngTuple[][]>(data);
    const [tool, setTool] = useState<Tool | null>(null);

    const isDrawing = tool === Tool.drawer;
    const isCleaning = tool === Tool.cleaner;
    const isScissoring = tool === Tool.scissors;

    useEffect(() => {
        setPolygons(data);
    }, [data]);

    const mapClick = (event: LeafletMouseEvent) => {
        if (isDrawing || isScissoring) {
            const newCoordinates = [...coordinates, [event.latlng.lat, event.latlng.lng] as LatLngTuple];
            setCoordinates(newCoordinates);
        }
    };

    const markerHandlers = (index: number) => !index && (isDrawing || isScissoring) ? {
        click: stopDrawing,
    } : undefined

    const polygonHandlers = (index: number) => ({
        click: removePolygonByIndex(index)
    })

    const removePolygonByIndex = (index: number) => () => {
        if (!isCleaning) return;
        const newPolygons = [...polygons];
        newPolygons.splice(index, 1);
        setPolygons(newPolygons);
    };

    const saveCoordinates = () => {
        mutate({
            geoCollection: [{
                type: 'FilteredArea',
                geometry: {
                    type: 'Polygon',
                    coordinates: polygons
                }
            }]
        })
        console.log('Сохраненные координаты:', polygons);
        setCoordinates([]);
    };

    const applyCutout = (cutOutPolygon: [number, number][]) => {

        const turfCutoutPolygon = polygon([cutOutPolygon.map(coordinateReverse)]);
        const newPolygons: LatLngTuple[][] = [];

        polygons.forEach((existingPolygon) => {
            const turfExistingPolygon = polygon([existingPolygon.map(coordinateReverse)]);
            const diff = difference(turfExistingPolygon, turfCutoutPolygon);

            if (diff && diff.geometry) {
                if (diff.geometry.type === "Polygon") {
                    newPolygons.push(diff.geometry.coordinates[0].map((coord: Position) => [coord[1], coord[0]]));
                } else if (diff.geometry.type === "MultiPolygon") {
                    diff.geometry.coordinates.forEach((multiCoord: Position[][]) => {
                        newPolygons.push(multiCoord[0].map((coord: Position) => [coord[1], coord[0]]));
                    });
                }
            }
        });

        setPolygons(newPolygons);
        setCoordinates([]);
    };

    const setPolygonCoordinates = (newPolygon: [number, number][]) => {

        const newTurfPolygon = polygon([newPolygon.map(coordinateReverse)]);

        // Find the intersection with the Israel borders
        const intersection = intersect(newTurfPolygon, israelBorders);
        if (!(intersection && intersection.geometry.type === "Polygon")) {
            setCoordinates([]);
            return;
        }
        // Use the intersecting part as the new polygon
        const intersectingPolygon: LatLngTuple[] = intersection.geometry.coordinates[0].map((coord: Position) => [coord[1], coord[0]]);

        let newPolygons: LatLngTuple[][] = [...polygons, intersectingPolygon];

        let hasIntersections = true;

        while (hasIntersections) {
            hasIntersections = false;

            for (let i = 0; i < newPolygons.length; i++) {
                for (let j = i + 1; j < newPolygons.length; j++) {
                    const poly1 = polygon([newPolygons[i].map(coordinateReverse)]);
                    const poly2 = polygon([newPolygons[j].map(coordinateReverse)]);
                    const intersection = intersect(poly1, poly2);

                    if (intersection) {
                        const mergedPolygon = union(poly1, poly2);
                        if (mergedPolygon && mergedPolygon.geometry && mergedPolygon.geometry.type === "Polygon") {
                            newPolygons[i] = mergedPolygon.geometry.coordinates[0].map((coord: Position) => [coord[1], coord[0]]);
                            newPolygons.splice(j, 1);
                            hasIntersections = true;
                            break;
                        }
                    }
                }

                if (hasIntersections) {
                    break;
                }
            }
        }

        setPolygons(newPolygons);
        setCoordinates([]);
    };

    function stopDrawing() {
        if (coordinates.length >= 3) {
            const polygon = [...coordinates, coordinates[0]]
            if (isScissoring) {
                applyCutout(polygon);
            } else {
                setPolygonCoordinates(polygon);
            }
        }
    }

    const toggleDrawing = () => {
        if (isDrawing) {
            stopDrawing();
            setTool(null);
        } else {
            setTool(Tool.drawer);
        }
    };

    const toggleCleaning = () => {
        setTool(isCleaning ? null : Tool.cleaner);
    };

    const toggleScissoring = () => {
        setTool(isScissoring ? null : Tool.scissors);
    };

    useEffect(() => {
        setCoordinates([]);
    }, [tool])

    const bounds: LatLngBoundsExpression = [
        [29.4797, 34.2674], // Юго-западный угол Израиля
        [33.3356, 35.8969], // Северо-восточный угол Израиля
    ];

    return (
        <div style={{position: 'relative'}}>
            <MapContainer center={[31.5, 34.75]} zoom={8} maxBounds={maxBounds} minZoom={8} bounds={bounds}
                          style={{width: '100%', height: '100vh'}}>
                <MapEventHandler click={mapClick}/>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <MaskLayer/>
                {polygons.map((polygon, index) => (
                    <Polygon key={index} positions={polygon} eventHandlers={polygonHandlers(index)}/>
                ))}
                {coordinates.map((coordinate, index) => (
                    <Marker key={index} position={coordinate} icon={isScissoring ? OutCutIcon : DrawerIcon}
                            eventHandlers={markerHandlers(index)}/>
                ))}
                {coordinates.length >= 2 &&
                    <Polyline positions={coordinates} pathOptions={isScissoring ? {color: 'red'} : undefined}/>}
            </MapContainer>
            <div style={{position: 'fixed', top: '10px', right: '10px', zIndex: 9000}}>
                <button onClick={saveCoordinates}>Сохранить координаты</button>
                <button onClick={toggleDrawing}>
                    {isDrawing ? 'Остановить рисование' : 'Нарисовать область на карте'}
                </button>
                <button onClick={toggleCleaning}>
                    {isCleaning ? 'Остановить удаление' : 'Удалить область'}
                </button>
                <button onClick={toggleScissoring}>
                    {isScissoring ? 'Остановить вырезание' : 'Вырезать область'}
                </button>
            </div>
        </div>
    );
};
