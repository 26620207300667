import React from "react";
import { GeoJSON } from "react-leaflet";
import {bboxPolygon, difference, polygon} from "@turf/turf";


const borderCoordinates = [
        [
            31.711768705190224,
            34.296613745391376
        ],
        [
            32.89568838035957,
            34.686628393828876
        ],
        [
            33.171999107068224,
            34.85691647976637
        ],
        [
            33.10706161472039,
            35.08965636603535
        ],
        [
            33.11624850643425,
            35.30914052389563
        ],
        [
            33.11164751049834,
            35.31463368795813
        ],
        [
            33.109346922163624,
            35.32012685202063
        ],
        [
            33.085187107966604,
            35.34209950827063
        ],
        [
            33.079433792597634,
            35.34484609030188
        ],
        [
            33.06332250755873,
            35.37780507467688
        ],
        [
            33.07598162273239,
            35.42724355123938
        ],
        [
            33.08288582698158,
            35.42999013327063
        ],
        [
            33.08978948927672,
            35.43136342428625
        ],
        [
            33.100143966429464,
            35.4890416469425
        ],
        [
            33.12314954847241,
            35.49865468405188
        ],
        [
            33.254166246756796,
            35.538480123505
        ],
        [
            33.29435133277863,
            35.56319936178625
        ],
        [
            33.28631579530442,
            35.5879186000675
        ],
        [
            33.280575672641035,
            35.5879186000675
        ],
        [
            33.2759833027121,
            35.61950429342688
        ],
        [
            33.30468165067698,
            35.68542226217688
        ],
        [
            33.33795990658183,
            35.72662099264563
        ],
        [
            33.34539093008666,
            35.7833811454475
        ],
        [
            33.34080197289063,
            35.79711405560374
        ],
        [
            33.33621277387793,
            35.81084696576
        ],
        [
            33.274234927856284,
            35.83831278607249
        ],
        [
            33.262752716256905,
            35.83556620404125
        ],
        [
            33.15934491265411,
            35.85753886029125
        ],
        [
            33.06962605777513,
            35.87127177044749
        ],
        [
            32.94985849551654,
            35.90972391888499
        ],
        [
            32.82762050459133,
            35.84929911419749
        ],
        [
            32.77221201325265,
            35.80810038372874
        ],
        [
            32.74218466652547,
            35.74767557904124
        ],
        [
            32.693657571634056,
            35.67901102825999
        ],
        [
            32.663603760900585,
            35.61583964154124
        ],
        [
            32.6566668302264,
            35.59936014935374
        ],
        [
            32.60346581489538,
            35.57464091107249
        ],
        [
            32.536341215510745,
            35.56914774700999
        ],
        [
            32.462214428365954,
            35.58837382122875
        ],
        [
            32.41353553746339,
            35.56640116497875
        ],
        [
            32.406579264851736,
            35.56090800091625
        ],
        [
            32.39498428574814,
            35.55541483685375
        ],
        [
            32.39730340070004,
            35.40435282513499
        ],
        [
            32.43903728231005,
            35.40435282513499
        ],
        [
            32.49233580371261,
            35.39061991497875
        ],
        [
            32.4946524150566,
            35.37688700482249
        ],
        [
            32.51318315792294,
            35.29448954388499
        ],
        [
            32.53402567835898,
            35.22033182904124
        ],
        [
            32.50391826382766,
            35.14068095013499
        ],
        [
            32.45062660053043,
            35.09124247357249
        ],
        [
            32.383994113948674,
            35.04821523092688
        ],
        [
            32.35994007711892,
            35.05622736644
        ],
        [
            32.34253807072107,
            35.03837458323688
        ],
        [
            32.33499616166707,
            35.01914850901812
        ],
        [
            32.281024178883406,
            35.01090876292438
        ],
        [
            32.28044366029941,
            35.02395502757282
        ],
        [
            32.26418763157398,
            35.03288141917438
        ],
        [
            32.24734796034897,
            35.02670160960407
        ],
        [
            32.207056210544216,
            34.990397533401854
        ],
        [
            32.19078704520486,
            34.957438549026854
        ],
        [
            32.168702811509895,
            34.976664623245604
        ],
        [
            32.145450464336996,
            34.987650951370604
        ],
        [
            32.118702932470846,
            34.99451740644873
        ],
        [
            32.092496812001706,
            34.98543314170093
        ],
        [
            32.023828047404685,
            35.00465921591967
        ],
        [
            31.952292315737406,
            34.99757973477245
        ],
        [
            31.942970063764104,
            35.005819480866194
        ],
        [
            31.891680776150025,
            35.0552579574287
        ],
        [
            31.90440816986831,
            35.03830484580249
        ],
        [
            31.86417827033235,
            35.03418497275562
        ],
        [
            31.850764399774295,
            34.99573282431812
        ],
        [
            31.834431836780546,
            34.977880041114986
        ],
        [
            31.83326511450156,
            35.02045206259936
        ],
        [
            31.863011924277664,
            35.066457311622806
        ],
        [
            31.854263858491723,
            35.09323648642749
        ],
        [
            31.792320216344276,
            35.19166940823198
        ],
        [
            31.809002794229876,
            35.16510485205799
        ],
        [
            31.809586310525415,
            35.166478143073626
        ],
        [
            31.832340570619035,
            35.18158434424549
        ],
        [
            31.8912421942794,
            35.20287035498768
        ],
        [
            31.884828992407467,
            35.226216302253306
        ],
        [
            31.847506961605585,
            35.25505541358143
        ],
        [
            31.830590442012674,
            35.26604174170643
        ],
        [
            31.78682644405719,
            35.268101678229876
        ],
        [
            31.731362296116995,
            35.27084826026112
        ],
        [
            31.699820496147257,
            35.24338243994862
        ],
        [
            31.72785818162816,
            35.15137194190175
        ],
        [
            31.689888449706466,
            35.10056017432362
        ],
        [
            31.658332545878075,
            35.04288195166737
        ],
        [
            31.595847714708203,
            34.96199604123831
        ],
        [
            31.503393936491584,
            34.95512958616019
        ],
        [
            31.40029969840227,
            34.88646503537894
        ],
        [
            31.349882864065158,
            34.92491718381643
        ],
        [
            31.365128015754312,
            35.01006122678518
        ],
        [
            31.356919395081036,
            35.124044381082065
        ],
        [
            31.376853373250924,
            35.225667916238315
        ],
        [
            31.491684397583448,
            35.398702584207065
        ],
        [
            31.491684397583448,
            35.47423359006644
        ],
        [
            31.351055655797968,
            35.46050067991018
        ],
        [
            31.243097636360595,
            35.401449166238315
        ],
        [
            31.181167150698553,
            35.44659820385277
        ],
        [
            31.057722567590545,
            35.469944151118405
        ],
        [
            31.00829980751853,
            35.43286529369653
        ],
        [
            29.947835751846114,
            35.14165392145515
        ],
        [
            29.742957552244995,
            35.0620030425489
        ],
        [
            29.428579268141988,
            34.95937284082174
        ],
        [
            29.430971427332125,
            34.89345487207175
        ],
        [
            30.04863532330469,
            34.71218045800925
        ],
        [
            30.307437042762192,
            34.56386502832175
        ],
        [
            30.506415780693033,
            34.50344022363424
        ],
        [
            31.087722431958586,
            34.26811091601849
        ],
        [
            31.263904415527033,
            34.22828815877438
        ],
        [
            31.43279012886836,
            34.398576244711876
        ],
        [
            31.54053039079201,
            34.53041218221188
        ],
        [
            31.711768705190224,
            34.296613745391376
        ]
    ];


const reversedCoordinates = borderCoordinates.map(coord => [coord[1], coord[0]]);

export const israelBorders = polygon([reversedCoordinates]);
// Создайте большой прямоугольник, который покроет всю вашу карту
const bigRectangle = bboxPolygon([-180, -90, 180, 90]);

// Вырежите из прямоугольника форму Израиля
const mask = difference(bigRectangle, israelBorders) ?? bigRectangle;

export const MaskLayer: React.FC = () => {
    const maskStyle = {
        fillColor: "#000",
        fillOpacity: 0.7,
        color: "#000",
        opacity: 0,
    };

    return (
        <GeoJSON key="mask-layer" data={mask} style={maskStyle} />
    );
};
