import {useMutation, useQuery} from "react-query";
import {MutationFunction} from "react-query/types/core/types";


const hash = window.location.pathname.split('/').at(1);

const collectionsApi =
    process.env.REACT_APP_COLLECTIONS_API
    || '/collections';

const fetchCoordinates = async () => {
    const response = await fetch(`${collectionsApi}/${hash}`)
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }
    return response.json()
};

const updateCoordinates: MutationFunction = ({geoCollection}: any) => fetch(
    `${collectionsApi}/${hash}`,
    {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({geoCollection})
    }
);

export function useCoordinates() {
    const {data: rawData} = useQuery<any[], Error>('coordinates', fetchCoordinates)
    const {mutate} = useMutation<any, any, any>(updateCoordinates)

    const data = rawData?.[0]?.geometry?.coordinates ?? []

    return {
        data,
        mutate
    }
}
